import React, {useState, useEffect} from 'react';
import '../index.css'

import { Link } from 'react-router-dom'
import { API_KEY } from '../security/api_key';
import {GetCurrentAddress} from './bet_contract';
import {Loading} from './loading';


export const Notifications = (props) => {

    const [newBets, setNewBets] = useState([]);
    const [newComments, setNewComments] = useState([]);
    const [newStatus, setNewStatus] = useState([]);
    const [clearing, setClearing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const api_retrieveNotifications = async(setNewBets, setNewComments, setNewStatus, setIsLoading) => {
            
            const address = await(GetCurrentAddress());
            if(address === -1) {
                return;
            }

            //make API call to retrieve notifications
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
                },
                body: JSON.stringify({"account": address})
            };

            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/retrieve-notifications', requestOptions);
            const data = await response.json();
            const result = data.body;
            
            if(result.newNotifications) {
                if(result.notificationsObj === undefined) {
                    return;
                } 
                const notifications = result.notificationsObj;
                if(notifications.newBets !== undefined){
                    setNewBets(notifications.newBets)
                }
                if(notifications.newComments !== undefined){
                    setNewComments(notifications.newComments)
                }
                if(notifications.newStatus !== undefined){
                    setNewStatus(notifications.newStatus)
                }
                setIsLoading(false);
            }
        }
        api_retrieveNotifications(setNewBets, setNewComments, setNewStatus, setIsLoading);
    }, [setNewBets, setNewComments, setNewStatus, setIsLoading])
    
    return (
        <div className='home-center'>
        {isLoading &&
            <Loading/>
        }

        {!isLoading &&
        <div>
            {!clearing &&
                <div className='float-container'>
                    <button className='button-grey' onClick = {() => {ClearAllNotifcations({
                                                                        setNewBets: setNewBets,
                                                                        setNewComments:setNewComments,
                                                                        setNewStatus:setNewStatus,
                                                                        setClearing:setClearing,
                                                                        setDisplayNotifications:props.setDisplayNotifications
                                                                                            })}}>
                            <h2>Clear All</h2>
                        </button> 
            </div> 
            }
            {clearing &&
                <h1 className='bright-pink'>Clearing Notifications...</h1>
            }

             {newBets.length > 0 &&
                <div className={'FancyBorder FancyBorder-blue'}>
                <div className='bright-pink-large'>New Bets</div>
                    {newBets.slice(0).map((item, index) => (
                        <LinkToBet
                            key={item}
                            betID={item}
                            displayText={"New bet: "} />
                                                            ))
                    }
                </div>
             }
         
            {newComments.length > 0 &&
                <div className={'FancyBorder FancyBorder-blue'}>
                <div className='lime-large'>New Comments</div>
                    {newComments.slice(0).map((item, index) => (
                        <LinkToBet
                            key={item}
                            betID={item}
                            displayText={"New Comments: "} />
                                                                ))
                    }
             </div>
            }

            {newStatus.length > 0 &&
                <div className={'FancyBorder FancyBorder-blue'}>
                <div className='creamsicle-large'>New Bet Status Change</div>
                    {newStatus.slice(0).map((item, index) => (
                        <LinkToBet
                            key={item}
                            betID={item}
                            displayText={"New Status: "} />
                                                            ))
                    }
                </div>
            }

            {(newStatus.length === 0 && 
              newComments.length === 0 &&
              newBets.length === 0) &&
                <div className={'FancyBorder FancyBorder-blue'}>
                    <h2>No New Notifications :-(</h2>
                </div>
            }
        </div>
        }

        </div>
    );
}

function LinkToBet(props) {
    const linkURL = "/bet/" + props.betID;
    
    return(
        <div className='size-24'> {props.displayText}
        <Link
            className='orange'
            to={linkURL}> {linkURL}</Link>
        </div>
    )
}

function ClearAllNotifcations(props) {
    props.setClearing(true);
    const api_clearNotifications = async(props) => {
        const address = await GetCurrentAddress();
        if(address === -1) {
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-API-KEY": API_KEY
            },
            body: JSON.stringify({"account": address})
        }

        const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/clear-all-notifications',requestOptions);
        const data = await response.json();
        const result = JSON.parse(data.body);

        if(result.notificationsCleared) {
            props.setNewBets([]);
            props.setNewComments([]);
            props.setNewStatus([]);
            props.setDisplayNotifications(false);
        }
        props.setClearing(false);
    }
    api_clearNotifications(props);
}

