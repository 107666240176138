import React, { useState, useEffect } from 'react';
import '../index.css'

import { Link } from 'react-router-dom'

export const LedgerBet = (props) => {
    
    const [borderCss, setBorderCss] = useState(GetBorderColor(props));
    const [textCss, setTextCss] = useState(GetTextColor(props));


    return(
        <div className={borderCss}>
            <div className="grid-container">
                <div className="block-center">
                    <h1 className={textCss}>
                        <span className='light-blue'>{"Bet: "}</span>
                        {props.data.bet.S}</h1> 

                    <h2 className={'orange'}><span className='light-blue'>{"Status: "}</span>{
                        GetBetStatus(props)}</h2> <br></br>
                </div>
                <div className="block-center">
                    <LinkToBet
                        betID={props.data.betID.S} />
                </div>
            </div>
        </div>
    );
}

function LinkToBet(props) {
    
    const linkURL = "/bet/" + props.betID;

    return (
        <div className='ledger-button-center'>
        <Link 
            className='button-grey-nav'
            to={linkURL}>View Bet</Link>
        </div>
    );
}

function GetBorderColor(props) {
    if(props.data.betSettled.BOOL === true) {
        return 'FancyBorder FancyBorder-bright-pink';
    }
    if(props.data.draw.BOOL === true) {
        return 'FancyBorder FancyBorder-bright-pink';
    }
    if(props.data.betPlaced.BOOL === true) {
        return 'FancyBorder FancyBorder-lime'
    }
    return 'FancyBorder FancyBorder-creamsicle'
}

function GetTextColor(props) {
    if(props.data.betSettled.BOOL === true) {
        return 'bright-pink';
    }
    if(props.data.draw.BOOL === true) {
        return 'bright-pink';
    }
    if(props.data.betPlaced.BOOL === true) {
        return 'lime';
    }
    return 'creamsicle';
}

function GetBetStatus(props) {
    if(props.data.betSettled.BOOL === true) {
        return 'SUCCESS!';
    }
    if(props.data.draw.BOOL === true) {
        return 'SUCCESS!'
    }
    if(props.data.betPlaced.BOOL === true) {
        return 'Ready to Settle';
    }

    return (
        <div >
            <a className='orange' 
                href="https://www.dictionary.com/e/slang/yinz/">Yinz</a>  
                {" need to shake on it!"}
        </div>
        );
}