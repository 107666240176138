import React from 'react';

export const Help = (props) => {
    return (
        <div className='home-center'>
            <div className='line-center'>
                <h1>Visit us on Discord!</h1>
           </div>
           <div className='line-center'>
                <h1><a className='orange' href="https://discord.gg/Puhvu9X">https://discord.gg/Puhvu9X</a></h1>
           </div>
        </div>
    );
}