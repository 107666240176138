import './../index.css'
import React from 'react';

export const Loading = () => 
{
    return (
        <div className='home-center'>
            <h1>Loading...</h1>
        </div>
    )
}
