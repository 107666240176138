import React, {useState, useEffect} from 'react';
import './../index.css'

import {Interval} from './utils'
import {API_KEY} from '../security/api_key';
import {GetCurrentAddress} from './bet_contract';
import {addNewNotification} from './add_notifications';

export const CommentSection = (props) => {
    
    const [commentsQueried, setCommentsQueried] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentValue, setCommentValue] = useState("");
    const [lastTimestamp, setLastTimestamp] = useState(0);

    const betID = props.betInfo.betID;

    useEffect(() => {
        const loadCommentsInitial = async () => {
            await api_getComments(
                                betID, 
                                setComments,
                                setLastTimestamp);
            setCommentsQueried(true)
        }
        loadCommentsInitial();
    }, [betID, setComments, setLastTimestamp, setCommentsQueried]);

    Interval(() => {
        PollForNewComments(betID, setComments, lastTimestamp, setLastTimestamp);
    }, 1000 * 15);

    return (
        <div className={'FancyBorder FancyBorder-comment'}>
        {!commentsQueried &&
            <div className='line-center'>
        <h1>Loading ... </h1>
        </div>
        }
        
        {commentsQueried &&
            <div className='line-center'>
                <h1> COMMENTS </h1>
                {comments.slice(0).map((item, index) => (
                    <Comment 
                        key={item.timestamp}
                        data={item}
                        betInfo={props.betInfo}
                    />
                ))}
                <MakeComment
                    commentValue={commentValue}
                    setCommentValue={setCommentValue}
                    betInfo={props.betInfo}
                    setComments={setComments}
                    setLastTimestamp={setLastTimestamp} />
                
            </div>
        }
        </div>
    );
}

function Comment (props) {
    const nameStyle = (props.betInfo.oneName === props.data.name) ? 'comment-name-left' : 'comment-name-right'; 
    const bodyStyle = (props.betInfo.oneName === props.data.name) ? 'comment-body-left' : 'comment-body-right'; 

    const name = props.data.name;
    const body = props.data.comment;

    return (
        <div className='line-center'>
            <div className={nameStyle}>
                    {name}
                </div>
                <div className={bodyStyle}>
                    {body}
                </div>
        </div>
    );
}

function MakeComment (props) {
    return(
        <div>
        <div className='line-center'>
            <textarea 
                ref={props.textAreaRef}
                className='comment-section'
                name="paragraph_text"
                rows="3"
                onChange={(event) => props.setCommentValue(event.target.value)}
                onKeyDown={(event) => {EnterSubmitComment(event, props)}}
                value={props.commentValue}
                ></textarea>
        </div>
        <div className='line-center'>
        <button className='button-grey-comment'
                onClick = {() => {SubmitComment(props.betInfo, 
                                                props.commentValue,
                                                props.setComments,
                                                props.setCommentValue,
                                                props.setLastTimestamp)}}>
                <h2>Add Comment</h2>
            </button>
        </div>
        </div>
    );
}

function EnterSubmitComment(event, props) {
    if(event.key === 'Enter') {
        event.preventDefault();
        SubmitComment(props.betInfo, 
            props.commentValue,
            props.setComments,
            props.setCommentValue,
            props.setLastTimestamp)
    }
}

function SubmitComment(betInfo, comment, setComments, setCommentValue, setLastTimestamp) { 
    if(comment === "") {return;}
    const api_submitComment = async (betInfo, comment, setComments, setCommentValue, setLastTimestamp) => {
        const address = await GetCurrentAddress();
        if(address === -1) { return;}

        if(betInfo.oneAddress !== address && betInfo.twoAddress !== address) {
            alert("Address error, only players who are part of the bet can comment. Are you part of this?");
            return;
        }

        const commenterName = (betInfo.oneAddress === address) ? betInfo.oneName : betInfo.twoName;
        const notifyAccount = (betInfo.oneAddress === address) ? betInfo.twoAddress : betInfo.oneAddress;
        const timestamp = new Date().getTime().toString();

        const commentObj = {
            timestamp: timestamp,
            name: commenterName,
            comment: comment
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify(
                {
                    "betID": betInfo.betID,
                    "comment": commentObj
                }
            )
        };

        const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-bet-comment', requestOptions);
        const data = await response.json();
        const result = JSON.parse(data.body);
        if(!result.commentAdded) {
            setCommentValue(comment);
            return;
        }
        console.log("Submitting comment: ",notifyAccount)
        await addNewNotification({
            notifyAccount: notifyAccount,
            betID: betInfo.betID,
            newComment:true});
        GetComments(betInfo.betID, setComments, setLastTimestamp);
    }
    const commentCopy = comment;
    setCommentValue("");
    api_submitComment(betInfo, commentCopy, setComments, setCommentValue, setLastTimestamp);
}

function PollForNewComments(betID, setComments, lastTimestamp, setLastTimestamp) {
    const api_getLatestTimestamp = async() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-API-KEY": API_KEY
            },
            body: JSON.stringify({"betID": betID})
        };

        const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/get-latest-comment-timestamp', requestOptions);
        const data = await response.json();

        if(!data.body.timestampRetrieved) {
            return;
        }

        const timestamp = parseInt(data.body.latestTimestamp);

        if(lastTimestamp < timestamp){
            GetComments(betID, setComments, setLastTimestamp);
        }

    };
    api_getLatestTimestamp();
    
}

function GetComments(betID, setComments, setLastTimestamp) {
    api_getComments(betID, setComments, setLastTimestamp);
}

const api_getComments = async(betID, setComments, setLastTimestamp) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': API_KEY
        },
        body: JSON.stringify({
            "betID": betID
        })
    };

    const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/get-bet-comments', requestOptions);
    const data = await response.json();
    const result = data.body;
    
    if(!result.commentsRetrieved) {
        return;
    }
    
    setLastTimestamp(parseInt(result.commentsObj.latestTimestamp.N));
    var commentsArray = JSON.parse(result.commentsObj.comments.S)
    commentsArray.sort((a, b) => parseFloat(a.timestamp) - parseFloat(b.timestamp));
    setComments(commentsArray);
}
