import { API_KEY } from "../security/api_key"


export const addNewNotification = async (props) => {
    var apiURL = '';
    if(props.newStatus !== undefined) {
        apiURL = 'https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-bet-status-notification'
    }
    if(props.newComment !== undefined) {
        apiURL = 'https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-bet-comment-notification'
    }
    if(props.newBet !== undefined) {
        apiURL = 'https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-new-bet-notification'
    }

    if(apiURL === '') {
        return;
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "X-API-KEY": API_KEY
        },
        body: JSON.stringify({
            "notifyAccount": props.notifyAccount,
            "betID": props.betID
        })
    };

    const response = await fetch(apiURL,requestOptions);
    const data = await response.json();
    const result = data.body; 
}