import React, {useEffect} from 'react';
import './../index.css'

import {Link} from 'react-router-dom'
import {Interval} from './utils'
import {GetCurrentAddress} from './bet_contract'
import { API_KEY } from '../security/api_key';
import notifications from '../images/notifications.png';

export const NewNotificationsButton = (props) => {

    const display = props.displayNotifications;
    const setDisplay = props.setDisplayNotifications;

    useEffect(() => {
        PollForNotifications(setDisplay);
    }, [setDisplay]);
    
    Interval(() => {
        PollForNotifications(setDisplay);
    }, 1000 * 15);

    return (
        <div>
            {display &&
                <div className='sticky-right'>
                    <Link to='/notifications'>
                        <img
                            className='button-grey-notifications'
                            src={notifications}
                            alt="logo192"
                            height="104"
                            width="178" />  
                    </Link> 
                </div>
            }
        </div>
    );
}

function PollForNotifications(setDisplay) {
    const api_pollForNotifications = async(setDisplay) => {
        const address = await GetCurrentAddress();
        if(address === -1){
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-API-KEY": API_KEY
            },
            body: JSON.stringify({"account": address})
        };

        const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/retrieve-notifications',requestOptions);
        const data = await response.json();
        const result = data.body;

        if(result.newNotifications) {
            if(result.notificationsObj === undefined) {
                return;
            } 
            const notifications = result.notificationsObj;
            if(notifications.newBets !== undefined){
                if(notifications.newBets.length > 0) {
                    setDisplay(true);
                    return;
                }
            }
            if(notifications.newComments !== undefined){
                if(notifications.newComments.length > 0) {
                    setDisplay(true);
                    return;
                }
            }
            if(notifications.newStatus !== undefined){
                if(notifications.newStatus.length > 0) {
                    setDisplay(true);
                    return;
                }
            }
            setDisplay(false);
        }
    }
    api_pollForNotifications(setDisplay);
}