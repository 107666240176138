import React, {useEffect} from 'react';
import './../index.css'

import {API_KEY} from '../security/api_key';
import {Loading} from './loading';
import {addNewNotification} from './add_notifications'

const shortID = require('short-uuid');
const idGenerator = shortID();

export const GenerateBet = (props) => {
  
    useEffect(() => {
        const api_addNewBet = async(props) => {
            if(!props.playerInfo || !props.betInfo) {
                console.error("Trying to Generate bet without bet and player info!");
                return;
            }

            const playerInfo = {...props.playerInfo};
            const betInfo = {...props.betInfo};
            const betID = idGenerator.new();
            const createDate = new Date().getTime().toString();
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
                },
                body: JSON.stringify(
                    {
                        "betID": betID,
                        "oneName": playerInfo.oneName,
                        "twoName": playerInfo.twoName,
                        "oneAddress": playerInfo.oneAddress,
                        "twoAddress": playerInfo.twoAddress,
                        "bet": betInfo.bet,
                        "details": betInfo.details,
                        "settleDate": betInfo.date,
                        "createDate" : createDate,
                        "lastUpdate": createDate
                    }
                )
            };

            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-new-bet', requestOptions);
            const data = await response.json();

            const result = JSON.parse(data.body);
            if(result.betAdded) {
                //add bet to each players "bets" array on accounts table
                const oneRequestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "X-API-KEY": API_KEY
                    },
                    body: JSON.stringify(
                        {
                            "account" : playerInfo.oneAddress,
                            "betID": betID
                        }
                    )
                };

                const twoRequestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "X-API-KEY": API_KEY
                    },
                    body: JSON.stringify(
                        {
                            "account" : playerInfo.twoAddress,
                            "betID": betID
                        }
                    )
                };

                const responseOne = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-bet-to-account', oneRequestOptions);
                const dataOne = await responseOne.json();
                const resultOne = JSON.parse(dataOne.body);
                if(!resultOne.betAdded) {
                    console.log("Error storing bet on player one");
                }

                const responseTwo = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-bet-to-account', twoRequestOptions);
                const dataTwo = await responseTwo.json();
                const resultTwo = JSON.parse(dataTwo.body);
                if(!resultTwo.betAdded) {
                    console.log("Error storing bet on player two");
                }

                props.setStateFunc(props.trueState);
                props.setBetInfo(
                    {...betInfo, uuid: betID}
                );

                await addNewNotification({
                    notifyAccount: playerInfo.twoAddress,
                    betID: betID,
                    newBet:true});
            }
        }
        api_addNewBet(props);
    }, [props]);


    return (
        <div>
            <Loading />
        </div>
    );
}