import '../index.css'

import {contractABI} from './abi'
import detectEthereumProvider from '@metamask/detect-provider'


export const EnableWeb3 = async () => {
    const provider = await detectEthereumProvider();
    if(provider) {
        console.log("provider detected! " + provider)
        establishWeb3Connection(provider);
    } else {
        alert("Please install metamask !")
    }
}


function establishWeb3Connection(provider) {
    if(provider !== window.ethereum) {
        console.error('Do you have multiple wallets installed');
    } else {
        const Web3 = require("web3");
        const ethEnabled = () => {
            if (window.ethereum) {

                window.web3 = new Web3(window.ethereum);
                console.log("Chain ID:" + window.ethereum.chainId)
                return true;
            }
            return false;
        }

        if(ethEnabled())
        {
            console.log("eth enabled")
            //ConnectEthAccount(testFunction); //TODO: Handle tjhis on startup
        } else {
            alert("eth not enabled")
        }
    }
}

export const TestNetworkID = async () => {
    await EnableWeb3();
    var network = await window.ethereum.networkVersion;
    console.log("Network: ", network);
    if(network === 0x64) { console.log("YOOO")};
}

export const GetCurrentAddress = async () => {
    await EnableWeb3();
    let accounts;
    try{
        accounts = await window.ethereum.request({ method: 'eth_requestAccounts'});
        return accounts[0]; 
    } catch (error) {
        console.error("Unable to Get Current Address: " + error)
        return -1;
    }

}
export const Validate_xDaiChainId = async () => {
    await EnableWeb3();
    const chainId = parseInt(window.ethereum.networkVersion)
    console.log("chainId: " + chainId)
    
    if(chainId !== 100)
    {
        alert("Please join xDai chain to complete transfer")
        return false;
    }
    return true;
    

}
const dollarOfxDai = 1000000000000000000;
const pennyOfxDai = 10000000000000000;

export const Send_xDai = async (toAddress, fromAddress, toHouse) => {
    await EnableWeb3();
    var amount = dollarOfxDai;
    if(toHouse) {
        amount = pennyOfxDai;
    }
    var accounts;
    try{
        accounts = await window.ethereum.request({ method: 'eth_requestAccounts'});
        console.log("connected to wallet")
    } catch (error) {
        console.error(error)
    }

    if(accounts[0] !== fromAddress){
        alert("Make sure you are trying to settle bet with the same accoint as as a player who made handshake!")
        return;
    }
    const hexAmount = window.web3.utils.toHex(amount);
    await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [
            {
                from: fromAddress,
                to: toAddress,
                value: hexAmount
            }
        ]
    }, function(error,result ) {
        console.log("Send Result: " + result)
        console.log("Send error: " + error)
    } );
}


//TODO: Connect to wallet when join site (or press button?)
const ConnectEthAccount = async (testFunction) => {
    let accounts;
    try{
        accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log("Connected to wallet")
        console.log(accounts)
    } catch (error) {
        console.error(error);
    }
    var pool;

    const contractAddress = '0x258624e4058a841ecd0e25627F7512ebF35B7cBe' //How will we inject this?

    var contract = new window.web3.eth.Contract(contractABI, contractAddress);
    await contract.methods.viewBalance().call(function(error,result) {
        console.log("result: " + result)
        console.log("error: " + error)
        testFunction(result);
    });

    var testString = "testing this";
    const gas = await contract.methods.setContext(testString).estimateGas();
    console.log("Gas: " + gas)
    console.log(accounts)

    //var event = contract.testEmit;


    var event = contract.events.testEmit(function(error, result) {
        if (!error)
            console.log("Event emitted!");
    });

    // const emitGas = await contract.methods.funcTestEmit().estimateGas();

    // const sendAccount = accounts[0];
    //     await contract.methods.funcTestEmit().send({
    //         from: sendAccount,
    //         gas
    //     }, function(error,result) {
    //         console.log("test func emit: " + result)
    //     });
    
    if (true)
    {
        const sendAccount = accounts[0];
        await contract.methods.setContext(testString).send({
            from: sendAccount,
            gas
        }, function(error,result) {
            console.log("Set Result: " + result)
        });

        await contract.methods.getContext().call(function(error,result) {
            console.log("Get Context Result:" + result)
            console.log("Get Context Error: " + error)
        });

        const testThis = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    from: accounts[0],
                    to: contractAddress,
                    value: window.web3.utils.toHex(2500000000000000)
                }
            ]
        }, function(error,result) {
            console.log("Send Result: " + result)
            console.log("Send error: " + error)
        });
        // .then((txHash) => console.log(txHash))
        // .catch((error) => console.error);
    }
}