import React from 'react';
import './../index.css';

import {Link} from 'react-router-dom'

export const ShareLink = (props) => {
    console.log("Props: ", props);

    const displayURL = "https://buck.social/bet/" + props.betInfo.uuid;
    const linkURL = "/bet/" + props.betInfo.uuid;

    return (
        <div className='home-center'>
            <DisplayValue 
                displayTitle={"Share This Link With Your Friend!"} />
            <DisplayLink 
                linkURL={linkURL}
                displayURL={displayURL} />
            <SeeBetButton
                linkURL={linkURL} />
        </div>
    )
}

function DisplayLink(props) {
    return <div className='orange'> <h2>{props.displayURL}</h2></div>
}

function SeeBetButton(props) {
    return (
        <div className='float-container'>
            <Link className='button-grey-padding' to={props.linkURL}>Continue to Bet</Link>
        </div>
        );
}

function DisplayValue(props) {
    return(
        <div className='line-center'>
            <h2>{props.displayTitle}</h2>
        </div>
    );
}