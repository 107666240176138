import React, { useState, useEffect } from 'react';
import './../index.css'

import {API_KEY} from '../security/api_key';
import {Loading} from './loading';
import {addNewNotification} from './add_notifications';
import {GetCurrentAddress, Validate_xDaiChainId, Send_xDai} from './bet_contract';
import {ShakeState} from '../data_types/state_enums';
import {LoseState} from '../data_types/state_enums';
import {CommentSection} from './comment_section';
import {Interval} from './utils'
import {InfoTooltip} from './info_tooltip';
import {bet_shake} from './tooltip_data';

const HouseAddress = "0x007510bccb32c23b9ee4f2849241d32e3c46364b";

export const Bet = (props) => {
    const paramID = props.match.params.id;

    const [oneShakeState, setOneShakeState] = useState(ShakeState.NOT_SHOOK);
    const [twoShakeState, setTwoShakeState] = useState(ShakeState.NOT_SHOOK);
    const [loseState, setLoseState] = useState(LoseState.NO_LOSER);

    const [betLoaded, setBetLoaded] = useState(false);
    const [betInfo, setBetInfo] = useState(
        {
            oneName: "",
            twoName: "",
            oneAddress: "",
            twoAddress: "",
            oneSet: false, 
            twoSet: false,
            bet: "",
            details: [],
            settleDate: "",
            createDate: "",
            lastUpdate: "",
            winner: "",
            betPlaced: false,
            draw: false,
            betSettled: false,
            betID: props.betID
        }
    );

    useEffect(() => {
        api_getBet(setBetLoaded, setBetInfo, paramID);
    },[setBetLoaded,setBetInfo, paramID]);

    Interval(() => {
        const api_pollForBetchanges = async() => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
                },
                body: JSON.stringify({"betID": betInfo.betID})
            };

            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/bet-page-polling', requestOptions);
            const data = await response.json();

            const result = data.body;
            if(!result.lastUpdateRetrieved){
                return;
            }
            const timestamp = result.lastUpdate;
            if(betInfo.lastUpdate < timestamp) {
                api_getBet(setBetLoaded, setBetInfo, paramID);
            } 

        }
        api_pollForBetchanges();
    }, 1000 * 15);


    return (
        <div>
            {betLoaded &&
                <BetDisplay 
                    betInfo={betInfo}
                    setBetInfoFunc={setBetInfo}
                    oneShakeState={oneShakeState}
                    twoShakeState={twoShakeState}
                    setOneShakeState={setOneShakeState}
                    setTwoShakeState={setTwoShakeState}
                    setLoseState={setLoseState}
                    loseState={loseState}
                    />
            }
            {!betLoaded &&
                <Loading/>
            }
        </div>
    );
}

function BetDisplay(props) {
    const betInfo = props.betInfo;
    return(
        <div className='default-center'>
            {betInfo.betSettled && 
                    <DisplayWinner
                                displayTitle={"WINNER : "}
                                displayValue={betInfo.winner} />
            }
            {betInfo.draw && 
                <DisplayWinner 
                    displayTitle={"CONCLUSION: "}
                    displayValue={"DRAW!"} />
            }
            <DisplayTitle
                            displayTitle={"BET"}
                            displayValue={betInfo.bet} />
            {!betInfo.oneSet &&
                <DisplaySetValueWithButton
                                displayTitle={"Player One: "}
                                displayValue={betInfo.oneName}
                                playerAddress={betInfo.oneAddress}
                                notifyAccount={betInfo.twoAddress}
                                playerNum={1}
                                betID={betInfo.betID}
                                setBetInfoFunc={props.setBetInfoFunc}
                                shakeState={props.oneShakeState}
                                setShakeState={props.setOneShakeState} />
            }
            {betInfo.oneSet &&
                <DisplaySetValue
                                displayTitle={"Player One: "}
                                displayValue={betInfo.oneName} />
            }
            {!betInfo.twoSet &&
                <DisplaySetValueWithButton
                            displayTitle={"Player Two: "}
                            displayValue={betInfo.twoName}
                            playerAddress={betInfo.twoAddress}
                            notifyAccount={betInfo.oneAddress}
                            playerNum={2}
                            betID={betInfo.betID}
                            setBetInfoFunc={props.setBetInfoFunc}
                            shakeState={props.twoShakeState}
                            setShakeState={props.setTwoShakeState} />
            }
            {betInfo.twoSet &&
                <DisplaySetValue
                                displayTitle={"Player Two: "}
                                displayValue={betInfo.twoName} />
            }
                <DisplaySetValue
                                displayTitle={"Details: "}
                                displayValue={betInfo.details[0]} />
            {betInfo.settleDate &&
            <DisplaySetValue
                            displayTitle={"Settle Date: "}
                            displayValue={betInfo.settleDate} />
            }
            {(betInfo.betPlaced && 
             !betInfo.betSettled &&
             !betInfo.draw && 
              props.loseState === LoseState.NO_LOSER) && 
                <div>
                <button className='button-grey-lost' onClick = {() => {DeclareWinner(betInfo, props.setBetInfoFunc, props.setLoseState);}}>
                    <h2>I Lost :-(</h2>
                </button>
                <button className='button-grey-lost' onClick = {() => {DeclareDraw(betInfo, props.setBetInfoFunc, props.setLoseState);}}>
                    <h2>It's a Draw</h2>
                </button>
                </div>
            }
            {(betInfo.betPlaced && !betInfo.betSettled && props.loseState === LoseState.PENDING) &&
                <div className='bright-pink'><h1>Conclusion Pending...</h1></div>
            }
            
            <CommentSection 
                betInfo={betInfo} />
        </div>
    );
}

function DisplaySetValue(props) {
    return(
        <div className='line-center'>
            <h2>{props.displayTitle}
            <span className='orange'>{props.displayValue}</span></h2>
        </div>
    );
}

function DisplayTitle(props) {
    return(
        <div className='line-center'>
           <h1><div className='lime'>{props.displayValue.toUpperCase()}</div></h1>
        </div>
    );
}

function DisplayWinner(props) {
    return(
        <div className='line-center'>
           <h1>
                <div className='bright-pink-large'>
                    {props.displayTitle.toUpperCase()}
                    {props.displayValue.toUpperCase()}
                </div>
           </h1>
        </div>
    );
}


function ShakeOnIt(props) {
    const api_confirmPlayerShake = async(props) => {
        props.setShakeState(ShakeState.PENDING);
        //check for address match (need player address)
        const address = await GetCurrentAddress();
        if(address === -1) {
            props.setShakeState(ShakeState.NOT_SHOOK);
            return;
        }
        if(address !== props.playerAddress) {
            props.setShakeState(ShakeState.NOT_SHOOK);
            alert("Address doesn't match! Are you sure you are " + props.displayValue + "?");
            return;
        }
        //validate xDai chain
        if(!await Validate_xDaiChainId()){
            props.setShakeState(ShakeState.NOT_SHOOK);
            return;
        }
        //if address give penny to house
        Send_xDai(HouseAddress, address, true).then(() => {
            //update bet on api
            const api_updateHandShake = async(props) => {
                const updateTime = new Date().getTime().toString();
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "X-API-KEY": API_KEY
                    },
                    body: JSON.stringify({
                        "account":address,
                        "betID":props.betID,
                        "playerNum":props.playerNum,
                        "updateTime":updateTime
                    })
                };
                
                const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/shake-on-bet',requestOptions);
                const data = await response.json();
                const result = JSON.parse(data.body);
                SetBetInfoFromResponse(result, props.setBetInfoFunc);
                props.setShakeState(ShakeState.SHOOK);

                await addNewNotification({
                    notifyAccount: props.notifyAccount,
                    betID: props.betID,
                    newStatus:true});
            
            }
            api_updateHandShake(props);

        }).catch((error) => {
            props.setShakeState(ShakeState.NOT_SHOOK);
            alert("Unable to handshake as player, house payment failed.")
                console.log("Player failed to hanshake, error: ", error)
        });
    }
    api_confirmPlayerShake(props);
}

function DeclareWinner(betInfo, setBetInfo, setLoseState) {
    const async_declareWinner = async() => {
        setLoseState(LoseState.PENDING);
        const address = await GetCurrentAddress();
        if(address === -1) {
            setLoseState(LoseState.NO_LOSER);
            return; }
        if(!Validate_xDaiChainId()) { 
            setLoseState(LoseState.NO_LOSER);
            return false; }

        if(address !== betInfo.oneAddress && address !== betInfo.twoAddress) {
            setLoseState(LoseState.NO_LOSER);
            alert("Loser must have address of player one or player two. Are you sure you are part of this bet?");
            return;
        }

        var isPlayerOneWinner = address === betInfo.oneAddress ? false : true;
        var winnerName = isPlayerOneWinner ? betInfo.oneName : betInfo.twoName;
        var winnerAddress = isPlayerOneWinner ? betInfo.oneAddress : betInfo.twoAddress;
        const notifyAccount = address === betInfo.oneAddress ? betInfo.twoAddress : betInfo.oneAddress;
        
        Send_xDai(winnerAddress, address, false).then(() => {
            const api_declareWinner = async(betInfo, setBetInfo) => {
                const updateTime = new Date().getTime().toString();
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "X-API-KEY": API_KEY
                    },
                    body: JSON.stringify({
                        "betID":betInfo.betID,
                        "winner":winnerName,
                        "winnerAddress": winnerAddress,
                        "updateTime": updateTime
                    })
                };
                
                const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/declare-winner', requestOptions);
                const data = await response.json();
                const result = JSON.parse(data.body);
                SetBetInfoFromResponse(result, setBetInfo);
                setLoseState(LoseState.LOSER_DECLARED);
                await addNewNotification({
                    notifyAccount: notifyAccount,
                    betID: betInfo.betID,
                    newStatus:true
                });
            };
            api_declareWinner(betInfo, setBetInfo);
        }).catch((error) => {
            setLoseState(LoseState.NO_LOSER);
            alert("Unable to declare winner, payment failed.")
                console.log("Failed to pay winner, error: ", error);
        });
    };
    async_declareWinner(betInfo, setBetInfo);
}

function DeclareDraw(betInfo, setBetInfo, setLoseState) {
    const async_declareDraw = async(betInfo) => {
        setLoseState(LoseState.PENDING);
        const address = await GetCurrentAddress();
        if(address === -1){
            setLoseState(LoseState.NO_LOSER);
            return;
        }
        if(!(await Validate_xDaiChainId())) {
            setLoseState(LoseState.NO_LOSER);
            return false;
        }
        if(address !== betInfo.oneAddress && 
            address !== betInfo.twoAddress && 
            address !== HouseAddress) {
                setLoseState(LoseState.NO_LOSER);
                return false;
        }

        const notifyAccount = address === betInfo.oneAddress ? betInfo.twoAddress : betInfo.oneAddress;

        const api_declareDraw = async (betInfo, setBetInfo) => {
            const updateTime = new Date().getTime().toString();
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
                },
                body: JSON.stringify({
                    "betID": betInfo.betID,
                    "updateTime": updateTime,
                    "draw": true
                })
            };

            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/declare-draw',requestOptions);
            const data = await response.json();
            const result = JSON.parse(data.body);

            SetBetInfoFromResponse(result, setBetInfo);
            setLoseState(LoseState.DRAW_DECLARED);

            await addNewNotification({
                notifyAccount: notifyAccount,
                betID: betInfo.betID,
                newStatus:true});
        }
        api_declareDraw(betInfo,setBetInfo);
    }
    async_declareDraw(betInfo, setBetInfo);
}

function DisplaySetValueWithButton(props) {
    return(
        <div className='line-center'>
        <div className='player-shake-border'>
            <h2>{props.displayTitle}
            <span className='orange'>{props.displayValue + "    "} <br></br>
            {(props.shakeState === ShakeState.NOT_SHOOK) && 
            <button className='button-grey-shake' onClick = {() => {ShakeOnIt(props);}}>
               Shake on it!
            </button>}
            <InfoTooltip 
                tooltip={bet_shake} 
                tooltipID={"shake_info"}/>
            {props.shakeState === ShakeState.PENDING &&
                <div className='bright-pink'>Shake Pending...</div>
            }
            </span></h2>
            </div>
        </div>
    );
}

function SetBetInfoFromResponse(result, setBetInfo) {
    setBetInfo(
        {
            oneName: result.oneName.S,
            twoName: result.twoName.S,
            oneAddress: result.oneAddress.S,
            twoAddress: result.twoAddress.S,
            oneSet: result.oneSet.BOOL,
            twoSet: result.twoSet.BOOL,
            bet: result.bet.S,
            details: result.details.SS,
            settleDate: result.settleDate.S,
            createDate: result.createDate.N,
            lastUpdate: result?.lastUpdate?.N,
            winner: result.winner.S,
            winnerAddress: result.winnerAddress.S,
            betPlaced: result.betPlaced.BOOL,
            draw: result.draw.BOOL,
            betID: result.betID.S,
            betSettled: result.betSettled.BOOL
        }
    );
}

const api_getBet = async(setBetLoaded, setBetInfo, paramID) => {
    const betID  = paramID;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "X-API-KEY": API_KEY
        },
        body: JSON.stringify({
            "betID": betID
        })
    }

    const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/get-bet', requestOptions);
    const data = await response.json();
    const result = JSON.parse(data.body);
    if(result.betExists) {
        SetBetInfoFromResponse(result, setBetInfo)
        setBetLoaded(true);
    }
}

