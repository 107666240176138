import React, { useState,useEffect } from 'react';
import './../index.css'

import {API_KEY} from '../security/api_key';
import {GetCurrentAddress} from './bet_contract'
import {LedgerBet} from './ledger_bet'
import {Loading} from './loading'

export const MyBets = (props) => {

    const [notShookArray, setNotShookArray] = useState([]);
    const [placedArray, setPlacedArray] = useState([]);
    const [settledArray, setSettledArray] = useState([]);

    const [betsReceived, setBetsReceived] = useState(false);

    useEffect(() => {
        const api_GetMyBets = async() => {
            const address = await GetCurrentAddress();
            if(address === -1) { return; }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
                },
                body: JSON.stringify({"account": address})
            };

            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/get-my-bets', requestOptions);
            const data = await response.json();
            
            if(!data.body.betsRetrieved) {
                console.log("Did not retrieve bet");
                return;
            }
            var betsArray = data.body.betsObj.Bets;

            SetNotShookBets(betsArray, setNotShookArray);
            SetPlacedBets(betsArray, setPlacedArray);
            SetSettledBets(betsArray, setSettledArray);

            setBetsReceived(true);
            
        }
        api_GetMyBets();
    },[setBetsReceived, setNotShookArray, setPlacedArray, setSettledArray]);

    return(
        <div>
        {!betsReceived &&
            <Loading />
        }
        {betsReceived && 
            <DisplayBets
                notShookArray={notShookArray}
                placedArray={placedArray}
                settledArray={settledArray}
            />
        }
        </div>
    );
}

function DisplayBets (props) {
    return(
        <div className='default-center'>
            <div className={'FancyBorder FancyBorder-blue'}>
                <h2>My Bets</h2>
                {props.notShookArray.slice(0).map((item, index) => (
                        <LedgerBet 
                            key={item.betID.S}
                            data={item}
                        />
                    ))}
                    {props.placedArray.slice(0).map((item, index) => (
                        <LedgerBet 
                            key={item.betID.S}
                            data={item}
                        />
                    ))}
                    {props.settledArray.slice(0).map((item, index) => (
                        <LedgerBet 
                            key={item.betID.S}
                            data={item}
                        />
                    ))}
            </div>
        </div>);
}


function SetNotShookBets(objArray, setFunc) {
    var notShookBets = [];
    for(var i = 0; i < objArray.length; i++) {
        const bet = objArray[i];
        if((!bet.betSettled.BOOL === true) &&
            (!bet.draw.BOOL === true) &&
            (!bet.betPlaced.BOOL === true)) {
                notShookBets.push(bet);
            }
    }
    notShookBets.sort((a, b) => parseFloat(b.createDate.N) - parseFloat(a.createDate.N));
    setFunc(notShookBets);
}

function SetPlacedBets(objArray, setFunc) {
    var placedBets = [];
    for(var i= 0; i < objArray.length; i++) {
        const bet = objArray[i];
        if((bet.betPlaced.BOOL === true) &&
            (!bet.draw.BOOL === true) && 
            (!bet.betSettled.BOOL === true)) {
                placedBets.push(bet);
        }
    }
    placedBets.sort((a, b) => parseFloat(b.createDate.N) - parseFloat(a.createDate.N));
    setFunc(placedBets);
}

function SetSettledBets(objArray, setFunc) {
    var settledBets = [];
    for(var i=0; i < objArray.length; i++) {
        const bet = objArray[i];
        if((bet.betSettled.BOOL === true) || 
            bet.draw.BOOL === true) {
                settledBets.push(bet);
            }
    }
    settledBets.sort((a, b) => parseFloat(b.createDate.N) - parseFloat(a.createDate.N));
    setFunc(settledBets);
}