import React, {useEffect} from 'react';
import './../index.css'

import {API_KEY} from '../security/api_key';
import {Loading} from './loading';
import {GetCurrentAddress} from './bet_contract';
import {MakeBetState} from '../data_types/state_enums';

export const CheckForAccount = (props) => {

    useEffect(() => {

        const api_checkForAccountAsync = async(props) => {
            const address = await GetCurrentAddress();
            if(address === -1){return;}
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
                },
                body: JSON.stringify({"account": address})
            };
            
            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/check-for-account-address', requestOptions);
            const data = await response.json();
            const accountData = data.body;
            if(accountData.accountExists){
                if(accountData.userName) {
                    props.setStateFunc(props.trueState);
                            props.setPlayerInfo(
                                {...props.playerInfo, oneName : accountData.userName, oneAddress: address}
                            )
                        }
            } else {
                props.setStateFunc(props.falseState)
            }
        }
        api_checkForAccountAsync(props);

    }, [props]);

    return (
        <div>
            <Loading />
        </div>
    );
}