import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Main} from './main'


ReactDOM.render(
    <div>
        <div className='background-purple'>
            <Main />
        </div>
    </div>,

    document.getElementById('root')
);