/* eslint-disable no-multi-str */
import React from 'react';
import './../index.css';

//********* Set Bet.js */
export const setBet_details = () =>{
    return format_string(
        "Specifics:\n\
        Who takes what stance?\n\
        Data Source?\n\
        Etc"
    );
}

export const setBet_title = () => { 
    return format_string(
        "What's the bet?\n\
        (A Title)"
    );
}

//********* Bet.js */
export const bet_shake = () => {
    return format_string(
        "Confirm you agree to the details\n\
        and give a penny to the platform!"
    );
}

//********* create_account.js */
export const create_input = () => {
    return format_string(
        "This name will be publicly displayed.\n \n\
        Your public address will be stored to\n recognize you when you return."
    );
}

export const format_string = (testString) =>{ 
    return <div>
        {testString.split("\n").map((i,key) => {
            return <div key={key}>{i}</div>;
        })}
    </div>;
}