import React, {useState} from 'react';
import './index.js'
import {Route, BrowserRouter as Router, Switch} from "react-router-dom"

import { DropdownNav} from './components/dropdown_nav'
import { GameState } from './data_types/state_enums'
import { HomeScreen } from './components/home.js';
import { MakeBet } from './components/make_bet.js';
import { FriendManagment } from './components/friend_managment';
import { MyBets } from './components/my_bets'
import { Help } from './components/help'
import { Notifications } from './components/notifications'


//DEV!
import { Bet } from './components/bet';


export const Main = () =>
{
    const [currentState, setCurrentState] = useState(GameState.HOME);
    const [displayNotifications, setDisplayNotifications] = useState(false);

    const STATE = {
        [GameState.HOME]: <HomeScreen setState ={setCurrentState} />,
        [GameState.MAKE_BET]: <MakeBet />,
        [GameState.FRIEND_MANAGMENT]: <FriendManagment />,
        [GameState.DEV]: <Bet />
    }

    return (
        <div>
        <Router>
        <DropdownNav
            displayNotifications={displayNotifications}
            setDisplayNotifications={setDisplayNotifications}
        />
        <Switch>
        <Route exact path="/" render={ props =>
                        <Home 
                            STATE={STATE} 
                            currentState={currentState}
                            {...props} />} />
        <Route exact path="/bet/:id" render={props => <Bet {...props}/>} />
        <Route exact path="/friend-managment" render={props => <FriendManagment {...props}/>} /> 
        <Route exact path="/my-bets" render={props => <MyBets {...props}/>} />
        <Route exact path="/help" render={props => <Help {...props}/>} />
        <Route exact path="/notifications" render={props => <Notifications
                                                                displayNotifications={displayNotifications}
                                                                setDisplayNotifications={setDisplayNotifications}
                                                                {...props}/>} />
        <Route render={ props =>
                        <Home 
                            STATE={STATE} 
                            currentState={currentState}
                            {...props} />} />
        </Switch>
        </Router>
        </div>
    );
}

function Home(props) {
    return(<div>
            {props.STATE[props.currentState]}
        </div>);
}
