import React from 'react';

import ReactTooltip from "react-tooltip";
import question_mark_64 from '../images/question_mark_64.png';

export const InfoTooltip = (props) => {

    return (
        <span className='tooltip-pad'>
            <img
                src={question_mark_64}
                height="32"
                width="32" 
                data-tip 
                data-for={props.tooltipID}>
            </img>

            <ReactTooltip
                className="tooltip"
                id={props.tooltipID}
                place="top" 
                effect="solid"
                type="success">
                    {props.tooltip()}
            </ReactTooltip>
            </span>
    );

}