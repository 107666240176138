
export const GameState = {
    HOME: 'home',
    MAKE_BET: 'makeBet',
    FRIEND_MANAGMENT: 'friendManagment',
    DEV: 'dev',
}

export const MakeBetState = {
    CHECK_FOR_ACCOUNT: 'checkForAccount',
    CHOOSE_FRIEND: 'chooseFriend',
    CREATE_ACCOUNT: 'createAccount',
    SET_BET: 'setBet',
    SHARE_LINK: 'shareLink',
    ADD_FRIEND: 'addFriend',
    GENERATE_BET: 'generateBet',
    DEV: 'dev',
}

export const CreateAccountState = {
    MAIN: 'main',
    LOADING: 'loading',
}

export const ShakeState = {
    NOT_SHOOK: 'notShook',
    SHOOK: 'shook',
    PENDING: 'pending',
}

export const LoseState = {
    NO_LOSER: 'noLoser',
    LOSER_DECLARED: 'loserDeclared',
    DRAW_DECLARED: 'drawDeclared',
    PENDING: 'pending',
}