import React, {useState, useEffect, useRef} from 'react';

export const Interval = (callback, delay) => {

    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if(delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function IsMobile() {
    if(window.screen.width < 900) { return true;}
    return false;
}