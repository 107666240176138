import React from 'react';
import './../index.css'

import {GameState} from '../data_types/state_enums';
import {DropdownNav} from './dropdown_nav';

export const HomeScreen = (props) =>
{
    return (
        <div>
            <div className='home-center'>
                <h1>buck.social</h1>
                <div className='float-container'>
                    <button className='button-grey' onClick = {() => {props.setState(GameState.MAKE_BET)}}>
                        <h2>MAKE BET</h2>
                
                    </button>  
                </div>
            </div>
        </div>
    );
}