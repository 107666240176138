import React, {useState, useEffect } from 'react';
import './../index.css';

import {API_KEY} from '../security/api_key';
import {GetCurrentAddress} from './bet_contract';


export const ChooseFriend = (props) => {

    const [friendsList, setFriendsList] = useState(["Loading..."]);
    const [chosenFriend, setChosenFriend] = useState("");
    const [displayContinue, setDisplayContinue] = useState(true);

    useEffect(() => {
        const api_retrieveFriendList = async() => {
            const address = await GetCurrentAddress();
            if(address === -1) {
                return;
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-KEY": API_KEY
            },
                body: JSON.stringify({
                    "account": address
                })
            }

            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/retrieve-friends-list',requestOptions);
            const data = await response.json();
            const result = JSON.parse(data.body);
            if(result.noFriendsExist) {
                setFriendsList(["No Friends :-("]);
                return;
            }
            if(result.noAccountExists) {
                alert("Account error? Do you have an account?");
                return;
            }
            if(result.friendsArray) {
                setFriendsList(result.friendsArray);
            }
        }
        api_retrieveFriendList();
    }, [setFriendsList]);
    
    const defaultSelectValue = "Select...";

    let sortedList = friendsList.sort().map((item, index) => <option key={index}>{item}</option>)
    sortedList.unshift(<option key={-1}>{defaultSelectValue}</option>);
    
    return (
        <div className='home-center'>
            <h1>CHOOSE FRIEND</h1>
            <br></br>
            <div className='line-center'>
            <select className='button-grey' 
                onChange={(e) =>
                    SetFriendSelection(setChosenFriend, e.target.value) }>
                {sortedList}
            </select>
            </div> <br></br>
            <div className='line-center'>
                {displayContinue &&
                    <button className='button-grey' onClick = {() => {ContinueToSet(props, chosenFriend, setDisplayContinue)}}>
                        <h2>Continue</h2>
                    </button>
                }
                {!displayContinue && 
                <h2>Loading... </h2>
                }
            </div>
        </div>
    )
}

function SetFriendSelection(setChosenFriend, value) {
    if(VerifySelectInput(value)) {
        setChosenFriend(value);
    } else {
        setChosenFriend("");
    }
}

function VerifySelectInput(value) {
    
    const defaultSelectValue = "Select...";
    const emptySelectValue = "No Friends :-(";
    const loadingSelectValue = "Loading...";

    if (value !== defaultSelectValue &&
        value  !== emptySelectValue &&
        value !== loadingSelectValue) {
            return true;
        }
        return false;
}


function ContinueToSet(props, chosenFriend, setDisplayContinue) {
    if(chosenFriend.length !== 0){
        setDisplayContinue(false);
        api_getPlayerTwoAddress(props,chosenFriend, setDisplayContinue);
    }
}

const api_getPlayerTwoAddress = async(props, playerTwoName, setDisplayContinue) => {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "X-API-KEY": API_KEY
        },
        body: JSON.stringify({"userName": playerTwoName})
    };
    
    const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/get-address-with-username', requestOptions);
    const data = await response.json();
    const result = JSON.parse(data.body);
    if(result.accountFound){
        if(result.address) {
            props.setPlayerInfo(
                {...props.playerInfo, twoName : playerTwoName, twoAddress: result.address}
            )
        }
        props.setStateFunc(props.trueState);
    } else {
        alert("Add friend failed... uh-oh");
        setDisplayContinue(true);
    }

};