import React, {useState, useEffect} from 'react';

import {API_KEY} from '../security/api_key';
import {GetCurrentAddress} from './bet_contract';


export const FriendManagment = (props) => {

    const [userName, setUserName] = useState("");
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const api_retrieveUserList = async() => {
            
        const requestOptions = {
                method: 'GET',
                headers: {
                    "X-API-KEY": API_KEY
                }
            }
            const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/retrieve-user-list', requestOptions);
            const body = await response.json();
            if(!body[0].userName) {
                return;
            }
            var userNames = [];
            body.forEach((item, index) => {
                userNames.push(item.userName);
            })
            setUserList(userNames);
        }

        api_retrieveUserList();
    }, [setUserList])

    const api_addFriend = async(userName, props) => {
        const address = await GetCurrentAddress();
        if(address === -1) {
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "X-API-KEY": API_KEY
             },
            body: JSON.stringify({
                "account": address,
                "newFriend": userName
            })
        }

        const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-friend', requestOptions);
        const data = await response.json();
        const result = JSON.parse(data.body);

        if(ErrorCheck(result, userName)) { return; }

        alert(userName + " is now your friend!");
        
        if(props.trueState) {
            props.setPlayerInfo(
                {...props.playerInfo, twoName : userName, twoAddress : result.newFriendAddress}
            )
            setUserName("");
            props.setStateFunc(props.trueState);
            return;
        }

        setUserName("");
    }
    

    return (
        <div>
            <div className='home-center'>
                <div className='line-center'>
                    <h1>Add A New Friend</h1> 
                </div> <br></br>
                <div className='line-center'>
                    <DisplayUserDropdown 
                        userName={userName}
                        setUserName={setUserName}
                        userList={userList}
                    />
                </div> <br></br>
                <div className='line-center'>
                    <button className='button-grey' onClick = {() => {api_addFriend(userName, props)}}>
                        <h2>Add Friend</h2>
                    </button>
                </div>
            </div>
        </div>
    );
}

function DisplayUserDropdown(props)
{
    let optionList = props.userList.sort().map((item,index) => <option key={index}>{item}</option>);
    return(
        <div>
        <label >Choose Someone:</label>
        <input
            list="users"
            id="available-users"
            name="available-users"
            value={props.userName}
            onChange={(event) => {props.setUserName(event.target.value)}} />

<datalist id="users">
        {optionList}
</datalist>
</div>
    );
}

function ErrorCheck(result, userName) {
    if(result.friendAlreadyExists) {
        alert(userName + " is already your friend!");
        return true;
    }
    if(result.noUserExists) {
        alert(userName + " is not user... invite them!");
        return true;
    }
    if(result.noAccountExists) {
        alert("Hmmm... couldn't find your account... do you have an account? Do you exist?");
        return true;
    }
    if(result.accountIsSelf) {
        alert("You are trying to add yourself...");
        return true;
    }
    return false;
}