import React, {useState} from 'react';
import './../index.css'

import {API_KEY} from '../security/api_key';
import {Loading} from './loading';
import {GetCurrentAddress} from './bet_contract';
import {CreateAccountState} from '../data_types/state_enums'
import {InfoTooltip} from './info_tooltip';
import {create_input} from './tooltip_data';

export const CreateAccount = (props) => 
{
    const [nameValue, setNameValue] = useState("");
    const [currentState, setCurrentState] = useState(CreateAccountState.MAIN);

    const api_createAccount = async(props) => {

        if(nameValue.length === 0) {
            alert("Please enter a username!");
            return
        }

        setCurrentState(CreateAccountState.LOADING);

        const address = await GetCurrentAddress();
        if(address === -1) {
            setCurrentState(CreateAccountState.MAIN);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "X-API-KEY": API_KEY
             },
            body: JSON.stringify({
                "userName": nameValue,
                "account": address})
        };

        const response = await fetch('https://1punmxr4qh.execute-api.us-west-2.amazonaws.com/Dev/add-account', requestOptions)
        const data = await response.json();
        console.log("response data",data);
        props.setPlayerInfo(
            {...props.playerInfo, oneName : nameValue, oneAddress: address}
        )
        props.setStateFunc(props.trueState);

    }

    return(
        <div>
            {
                (currentState === CreateAccountState.MAIN) &&
                CreateAccoutUI(props, nameValue, setNameValue, api_createAccount)
            }
            {
                (currentState === CreateAccountState.LOADING) && 
                DisplayLoading()
            }

        </div>
        );
     
}

function DisplayLoading() {
    return (
        <div>
            <Loading />
        </div>
    );
}

function CreateAccoutUI(props, nameValue, setNameValue, api_createAccount) {
    return (
        <div>
            <div className='home-center'>
                <h1>Create Account</h1>
            
            <div className='float-container'>
            <form onSubmit={() => {console.log("Set ")}}>
                <label className='twenty-font'>
                    {"Enter Name:"}
                <input type="text"
                    className='input-grey-set'
                    value={nameValue}
                    onChange={(event) => {setNameValue(event.target.value)}} />
                    <InfoTooltip
                        tooltip={create_input}
                        tooltipID={"input"}
                    />
                </label> <br></br>
                 <br></br>
    </form> <br></br> <br></br> <br></br>
    <div className="horizontal-center">
    <button className='button-grey' onClick = {() => {api_createAccount(props)}}>
                        <h2>CREATE</h2>
                    </button>
            </div>
            </div>
        </div>
        </div>
    );   
}