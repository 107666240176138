import React, {useState} from 'react';
import './../index.css'

import {InfoTooltip} from './info_tooltip';
import {setBet_title,
        setBet_details} from './tooltip_data';

export const SetBet = (props) => {

    const [isTitleValue, setIsTitleValue] = useState(false);
    const [isDetailValue, setIsDetailValue] = useState(false);
    const [betInfo, setBetInfo] = useState (
        {
            oneName: props.playerInfo.oneName,
            twoName: props.playerInfo.twoName,
            oneAddress: props.oneAddres,
            twoAddress: props.twoAddress,
            bet: "",
            details:[],
            date:""
        }
    )
    const titleTooltip = <div>What's the bet?
                         <br></br> 
                         (A Title)</div>;
    const detailsTooltip = <div>Specifics:
                            <br></br> 
                            Who takes what stance?
                            <br></br>
                            Data Source?
                            <br></br>
                            Etc
                            </div>;
    const frameCSS = 'default-center';
    
    return(
        <div>
            <div className={frameCSS}>
                <div className='line-center'>
                    <h1>Set Bet Details</h1> 
                </div> 
                
                <DisplaySetValue
                    displayTitle={"Player One: "}
                    displayValue={betInfo.oneName}
                />
                <DisplaySetValue
                    displayTitle={"Player Two: "}
                    displayValue={betInfo.twoName}
                />

                <DisplaySetValue
                    displayTitle={"Bet Value: "}
                    displayValue={"$1"}
                />  
                
                {/* Bet Title */}
                <DisplaySingleInput
                    label={"Bet:"}
                    betData={betInfo}
                    setFunction={setBetInfo}
                    setIsValue={setIsTitleValue}
                    setParameter={"bet"}
                    tooltip={setBet_title}
                    tooltipID={"title"} />
                
                {/* Bet Details */}
                <DisplayArrayInput 
                    label={"Details: "}
                    betData={betInfo}
                    setFunction={setBetInfo}
                    setIsValue={setIsDetailValue}
                    setParameter={"details"}
                    tooltip={setBet_details}
                    tooltipID={"details"} />

                {/* Settle Date */}
                <DisplaySingleInput
                    label={"Settle Date (optional):"}
                    betData={betInfo}
                    setFunction={setBetInfo}
                    setParameter={"date"}
                    inputType='date' />
                
                
                {(!isDetailValue || !isTitleValue) && 
                    <div className='line-center'>
                    <br></br>
                    <br></br>
                    <button className='button-greyed-out'>
                        <h2>Continue</h2>
                    </button>
                </div>
                }

                {(isDetailValue && isTitleValue) && 
                    <div className='line-center'>
                    <br></br>
                    <br></br>
                    <button className='button-grey' onClick = {() => {GenerateBet(props, {...betInfo})}}>
                        <h2>Continue</h2>
                    </button>
                </div>
                }
            </div>
        </div> 
    );
}

function GenerateBet(props, betInfo) {
    console.log("Generate bet clicked");
    props.setMasterBet(
        {...props.masterBet, bet: betInfo.bet, details: betInfo.details, date: betInfo.date}
        );
    props.setStateFunc(props.trueState);
}

function SetSingleInput(value, props) {
    if(value.length !== 0) {
        if(props.setIsValue !== undefined) {
            props.setIsValue(true);
        }
        props.setFunction(
            {...props.betData, [props.setParameter]: value});

    } else if(props.setIsValue !== undefined) {
        props.setIsValue(false);
    }
}

function DisplaySingleInput(props) {
    var hasTooltip = false;
    if(props.tooltip) {
        hasTooltip = true;
    }
    return(
        <div className='line-center'>
            <label className='twenty-font'>{props.label} </label>
            <input className='input-grey-set' type={props.inputType}
                onChange={(event) => {SetSingleInput(event.target.value, props)}} 
                />
            {hasTooltip &&
            <InfoTooltip 
                tooltip={props.tooltip} 
                tooltipID={props.tooltipID}/> 
            }
            <br></br>
         </div> 
    );
}

function SetArrayInput(value, index, props) {
    const arrayParameter = props.setParameter;
    var valueArray = props.betData[arrayParameter];
    console.log("value: ", value);
    valueArray[index] = value;

    if(valueArray[0].length !== 0) {
        console.log("Set Value True");
        props.setIsValue(true);
        props.setFunction(
            {...props.betData, [props.setParameter]: valueArray});
    } else {
        props.setIsValue(false);
    }
} 

function DisplayArrayInput(props) {
    var hasTooltip = false;
    if(props.tooltip) {
        hasTooltip = true;
    }
    return(
        <div className='line-center'>
            <label className='twenty-font'>{props.label} </label>
            <input className='input-grey-set'
                onChange={(event) => {SetArrayInput(event.target.value, 0, props)}} />
            {hasTooltip &&
                <InfoTooltip tooltip={props.tooltip} tooltipID={props.tooltipID}/> 
            }
            <br></br>
         </div> 
    );
}

function DisplaySetValue(props) {
    return(
        <div className='line-center'>
            <h2>{props.displayTitle}
            <span className='orange'>{props.displayValue}</span></h2>
        </div>
    );
}