import React, {useState} from 'react';
import './../index.css'

import {MakeBetState} from '../data_types/state_enums';
import {CreateAccount} from './create_account';
import {CheckForAccount} from './check_for_account';
import {ChooseFriend} from './choose_friend';
import {FriendManagment} from './friend_managment';
import {SetBet} from './set_bet';
import {GenerateBet} from './generate_bet'
import {ShareLink} from './share_link';

//DEV!
import {Bet} from './bet'

export const MakeBet = (props) => 
{
    const [currentState, setCurrentState] = useState(MakeBetState.CHECK_FOR_ACCOUNT);
    const [playerInfo, setPlayerInfo] = useState( 
        {
            oneName: "",
            twoName: "",
            oneAddress: "",
            twoAddress: ""
        }
    ); 
    const [betInfo, setBetInfo] = useState(
        {
            bet: "",
            details:[],
            date: "",
            uuid:""
        }
    )

    const STATE = {
        [MakeBetState.CHECK_FOR_ACCOUNT]: <CheckForAccount 
                                            falseState={MakeBetState.CREATE_ACCOUNT}
                                            trueState={MakeBetState.CHOOSE_FRIEND}
                                            setStateFunc={setCurrentState}
                                            playerInfo={playerInfo}
                                            setPlayerInfo={setPlayerInfo} />,
        [MakeBetState.CREATE_ACCOUNT]: <CreateAccount 
                                            trueState={MakeBetState.ADD_FRIEND}
                                            setStateFunc={setCurrentState}
                                            playerInfo={playerInfo} 
                                            setPlayerInfo={setPlayerInfo} />,
        [MakeBetState.ADD_FRIEND]: <FriendManagment 
                                        trueState={MakeBetState.SET_BET}
                                        setStateFunc={setCurrentState}
                                        playerInfo={playerInfo} 
                                        setPlayerInfo={setPlayerInfo} />, 
        [MakeBetState.CHOOSE_FRIEND]: <ChooseFriend
                                            trueState={MakeBetState.SET_BET}
                                            setStateFunc={setCurrentState}
                                            playerInfo={playerInfo}
                                            setPlayerInfo={setPlayerInfo} />,
        [MakeBetState.SET_BET]: <SetBet 
                                    playerInfo={playerInfo}
                                    masterBet={betInfo}
                                    setMasterBet={setBetInfo}
                                    trueState={MakeBetState.GENERATE_BET}
                                    setStateFunc={setCurrentState} />,
        [MakeBetState.GENERATE_BET]: <GenerateBet 
                                        betInfo={betInfo}
                                        setBetInfo={setBetInfo}
                                        playerInfo={playerInfo} 
                                        trueState={MakeBetState.SHARE_LINK}
                                        setStateFunc={setCurrentState} />,
        [MakeBetState.SHARE_LINK]: <ShareLink 
                                        betInfo={betInfo}/>,
        [MakeBetState.DEV] : <Bet />
    }

    return (
        <div>
            
           {STATE[currentState]}
        </div>
    );
}