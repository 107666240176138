import React, {useState} from 'react';
import './../index.css';

import {Link} from 'react-router-dom'
import {NewNotificationsButton} from './new_notifications'
import {TestNetworkID} from './bet_contract'

import logo192 from '../images/logo192.png';

export const DropdownNav = (props) => {

    const [displayMenu, setDisplayMenu] = useState(false);
    const [displayMenuTouch, setDisplayMenuTouch] = useState(false);
    const {navOpacityClass, setNavOpacityClass} = useState('opaque-hover');

    return (
        
        <div>
        <NewNotificationsButton
            displayNotifications={props.displayNotifications}
            setDisplayNotifications={props.setDisplayNotifications} />
            
        <div className='sticky-left' onMouseLeave={() => setDisplayMenu(false)}>
            <img
                className={navOpacityClass}
                src={logo192}
                alt="logo192"
                height="96"
                width="96"
                onClick={() => {
                    if(window.screen.width < 900) {
                        setDisplayMenu(true);
                        console.log("width low");
                    } else {
                        setDisplayMenu(!displayMenu);
                        console.log("width high");
                    }}}
                onMouseEnter={() => setDisplayMenu(true)}
                 />

            
            {(displayMenu || displayMenuTouch) && 
            <div className="menu">
                <LinkToHome />
                <LinkToFriendManagment />
                <LinkToMyBets />
                <LinkToHelp />
            </div>
            }
        </div>
        </div>
    );
}


function LinkToFriendManagment() {
    return (
        <Link 
            className='button-grey-nav'
            to='/friend-managment'>Add Friend</Link>
    );
}

function LinkToHome() {
    return (
        <Link
             className='button-grey-nav'
             to="/">Make a Bet</Link>
    );
}

function LinkToMyBets() {
    return (
        <Link 
            className='button-grey-nav'
            to="/my-bets">See My Bets</Link>
    );
}

function LinkToHelp() {
    return (
        <Link className='button-grey-nav'
        to="/help">Help</Link>
    );
}
